import React, { useEffect, useCallback } from 'react';
import { IoClose } from "react-icons/io5";
import '../MoviePage.css';

function MoviePageTrailer({ trailer, showTrailer, handleCloseTrailer }) {
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      handleCloseTrailer();
    }
  }, [handleCloseTrailer]);

  useEffect(() => {
    if (showTrailer) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showTrailer, handleKeyDown]);

  if (!showTrailer || !trailer) return null;

  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
    }
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const embedUrl = getEmbedUrl(trailer);

  return (
    <div className="trailer-modal" onClick={handleCloseTrailer}>
      <div className="trailer-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-trailer" onClick={handleCloseTrailer}>
          <IoClose />
        </button>
        <iframe
          width="100%"
          height="100%"
          src={`${embedUrl}?autoplay=1`}
          title="Movie Trailer"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default MoviePageTrailer;