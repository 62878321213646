import React, { useState, useRef, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const ratingCategories = [
  { name: 'scares', display_name: 'Scares' },
  { name: 'atmosphere', display_name: 'Atmosphere' },
  { name: 'plot', display_name: 'Plot' },
  { name: 'lore', display_name: 'Lore' },
  { name: 'villain', display_name: 'Villain' },
  { name: 'gore', display_name: 'Gore' },
  { name: 'acting', display_name: 'Acting' },
  { name: 'unpredictability', display_name: 'Unpredictability' }
];

export default function DetailedRatingsSpiderchart({ userRatings, communityRatings }) {
    const [showTable, setShowTable] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const chartRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!communityRatings || Object.keys(communityRatings).length === 0) {
        return null;
    }

    const hasUserRatings = userRatings && Object.values(userRatings).some(rating => rating !== -1);

    const getRatingValue = (ratings, category) => {
        if (!ratings) return null;
        const rating = ratings[category.name];
        return typeof rating === 'object' ? rating.rating : (rating === -1 ? null : rating);
    };

    const datasets = [
        {
        label: 'Community Ratings',
        data: ratingCategories.map(category => getRatingValue(communityRatings, category)),
        backgroundColor: 'rgba(155, 192, 177, 0.4)', // #9BC0B1 with 0.4 opacity
        borderColor: 'rgba(155, 192, 177, 1)', // #9BC0B1
        borderWidth: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        tension: 0.3,
        fill: true,
        }
    ];

    if (hasUserRatings) {
        datasets.push({
        label: 'Your Ratings',
        data: ratingCategories.map(category => getRatingValue(userRatings, category)),
        backgroundColor: 'rgba(191, 39, 34, 0.2)',
        borderColor: 'rgba(191, 39, 34, 1)',
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
        tension: 0,
        fill: true,
        });
    }

    const data = {
        labels: ratingCategories.map(category => category.display_name),
        datasets: datasets,
    };

    const options = {
        scales: {
        r: {
            angleLines: { display: true, color: 'rgba(255, 255, 255, 0.1)' },
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: { display: false },
            pointLabels: { 
            font: { 
                family: "'SUSE', sans-serif",
                size: 14
            },
            color: 'rgba(231, 231, 231, 0.8)', // Light grey color for labels
            callback: function(label) {
                return label;
            }
            }
        }
        },
        plugins: {
        legend: { display: false },
        tooltip: { enabled: false }
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <div 
        className="rating-page-spider-chart"
        ref={chartRef}
        onMouseEnter={() => isDesktop && communityRatings && setShowTable(true)}
        onMouseLeave={() => setShowTable(false)}
        >
        <Radar data={data} options={options} />
        {showTable && (
            <div className="ratings-comparison-table">
            <table>
                <thead>
                <tr>
                    <th>Category</th>
                    {hasUserRatings && <th style={{color: 'rgba(191, 39, 34, 1)'}}>Your Rating</th>}
                    <th style={{color: 'rgba(155, 192, 177, 1)'}}>Community</th>
                </tr>
                </thead>
                <tbody>
                {ratingCategories.map(category => (
                    <tr key={category.name}>
                    <td>{category.display_name}</td>
                    {hasUserRatings && <td>{userRatings[category.name] !== -1 ? userRatings[category.name] : '-'}</td>}
                    <td>{communityRatings[category.name]?.rating.toFixed(1) || '-'}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        )}
        </div>
    );
}