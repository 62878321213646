import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaBookmark, FaRegBookmark, FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { RxMixerHorizontal } from "react-icons/rx";
import { IoPeople, IoPerson, IoTicket } from "react-icons/io5";
import { RiCheckboxFill, RiCheckboxBlankLine } from "react-icons/ri";
import '../UserList.css';
import DesktopNavigationMenu from '../gizmos/DesktopNavigationMenu';
import '../../App.css';
import './UserLists.css'

const sortUserList = (userList) => {
  return [...userList].sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
};

const sortWatchedMovies = (watchedMovies) => {
  return [...watchedMovies].sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
};

export default function DesktopUserList({ userList, watchedMovies, onToggleBookmark, onToggleWatched, isInWatchlist }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeSection, setActiveSection] = useState(searchParams.get('view') || 'watchlist');
    const navigate = useNavigate();
    const [activeHoverItem, setActiveHoverItem] = useState(null);
    const hoverTimeoutRef = useRef(null);
    const appearTimeoutRef = useRef(null);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [isStreamingNow, setIsStreamingNow] = useState(false);
    const filterDropdownRef = useRef(null);

    const handleMouseEnter = useCallback((titleId) => {
        if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
        }
        appearTimeoutRef.current = setTimeout(() => {
        setActiveHoverItem(titleId);
        }, 200);
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (appearTimeoutRef.current) {
        clearTimeout(appearTimeoutRef.current);
        }
        hoverTimeoutRef.current = setTimeout(() => {
        setActiveHoverItem(null);
        }, 500);
    }, []);

    const handlePosterClick = useCallback((titleId, event) => {
        // Check if the click target is not part of the hover menu
        if (!event.target.closest('.user-list-item-hover-menu')) {
        navigate(`/movie/${titleId}`);
        }
    }, [navigate]);

    const sectionDisplayNames = [
        { key: 'watchlist', name: 'Watchlist' },
        { key: 'watched', name: 'Watched' },
    ];

    // Update URL when activeSection changes
    useEffect(() => {
        setSearchParams({ view: activeSection });
    }, [activeSection, setSearchParams]);

    // Update activeSection when URL changes
    useEffect(() => {
        const view = searchParams.get('view');
        if (view && (view === 'watchlist' || view === 'watched')) {
            setActiveSection(view);
        }
    }, [searchParams]);

    const handleSectionChange = (newSection) => {
        setActiveSection(newSection);
    };

    const groupWatchedMoviesByMonth = useCallback((sortedWatchedMovies) => {
        const now = new Date();
        const groups = {};

        // Create groups for the last 6 months
        for (let i = 0; i < 6; i++) {
            const monthDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
            const monthName = monthDate.toLocaleString('default', { month: 'long' });
            groups[monthName] = [];
        }

        groups['Others'] = [];

        sortedWatchedMovies.forEach(movie => {
            if (!movie.watchDate) {
                // If there's no watch date, put it in "Others"
                groups['Others'].push(movie);
            } else {
                const watchDate = new Date(movie.watchDate);
                const monthName = watchDate.toLocaleString('default', { month: 'long' });
                
                // Check if the watch date is within the last 6 months
                if (groups[monthName]) {
                    groups[monthName].push(movie);
                } else {
                    // If it's not in the last 6 months, put it in "Others"
                    groups['Others'].push(movie);
                }
            }
        });

        // Remove empty month groups
        Object.keys(groups).forEach(key => {
            if (groups[key].length === 0 && key !== 'Others') {
                delete groups[key];
            }
        });

        return groups;
    }, []);
    // eslint-disable-next-line
    const groupedWatchedMovies = useMemo(() => groupWatchedMoviesByMonth(sortWatchedMovies(watchedMovies)), [watchedMovies]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
                setIsFilterDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const availablePlatforms = useMemo(() => {
        const platforms = new Set();
        userList.forEach(item => {
            const providers = item.movieDetails.watch_providers;
            if (providers && providers.stream) {
                providers.stream.forEach(provider => platforms.add(provider.provider_name));
            }
        });
        return Array.from(platforms);
    }, [userList]);

    const filteredUserList = useMemo(() => {
        if (selectedPlatforms.length === 0) return userList;
        return userList.filter(item => {
            const providers = item.movieDetails.watch_providers;
            if (selectedPlatforms.includes('Not Available')) {
                return !providers || !providers.stream || providers.stream.length === 0;
            }
            if (!providers || !providers.stream) return false;
            return providers.stream.some(provider => selectedPlatforms.includes(provider.provider_name));
        });
    }, [userList, selectedPlatforms]);

    const handlePlatformChange = (platform) => {
        setSelectedPlatforms(prev => {
            if (platform === 'Not Available') {
                // If 'Not Available' is selected, clear all other selections
                return prev.includes('Not Available') ? [] : ['Not Available'];
            } else {
                // If any other platform is selected, remove 'Not Available' if it's there
                const newSelection = prev.filter(p => p !== 'Not Available');
                if (newSelection.includes(platform)) {
                    return newSelection.filter(p => p !== platform);
                } else {
                    return [...newSelection, platform];
                }
            }
        });
    };

    const handleClearFilter = () => {
        setSelectedPlatforms([]);
        setIsStreamingNow(false);
    };

    const handleSelectAllFilter = () => {
        setSelectedPlatforms([...availablePlatforms]);
        setIsStreamingNow(true);
    };

    const handleStreamingNowChange = () => {
        let currentState = Boolean(isStreamingNow)
        setIsStreamingNow(!currentState);
        if (currentState) {
            handleClearFilter();  
        } else {
            handleSelectAllFilter();
        }
    };

    return (
        <div className="user-list-desktop">
        <DesktopNavigationMenu 
            items={sectionDisplayNames}
            setActiveSection={handleSectionChange}
            activeSection={activeSection}
        />
        {activeSection === "watchlist" && sortUserList(userList).length === 0 &&
        <div className="user-list-empty">
            <p>Your watchlist is empty.</p>
            <p>Add some movies to get started.</p>
        </div>
        }
        {activeSection !== "watchlist" && sortWatchedMovies(watchedMovies).length === 0 &&
        <div className="user-list-empty">
            <p>You haven't watched any movies yet.</p>
            <p>Watch some movies to get started.</p>
        </div>
        }
        
        {activeSection === 'watchlist' && 
        <>
            <div className="desktop-user-list-filter-container" ref={filterDropdownRef}>
                <div className="desktop-user-list-filter">
                    <div className="filter-dropdown-container">
                        <div className="filter-dropdown-toggle" onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}>
                            <RxMixerHorizontal /> Service
                        </div>
                        {isFilterDropdownOpen && (
                            <div className="filter-dropdown-menu">
                                <div className="filter-dropdown-actions">
                                    <button onClick={handleClearFilter}>Clear All</button>
                                    <button onClick={handleSelectAllFilter}>Select All</button>
                                </div>
                                <div className="filter-checkbox-container">
                                    {availablePlatforms.map(platform => (
                                        <label key={platform} className="filter-checkbox-label">
                                            <input
                                                type="checkbox"
                                                checked={selectedPlatforms.includes(platform)}
                                                onChange={() => handlePlatformChange(platform)}
                                            />
                                            {platform}
                                        </label>
                                    ))}
                                    <label className="filter-checkbox-label">
                                        <input
                                            type="checkbox"
                                            checked={selectedPlatforms.includes('Not Available')}
                                            onChange={() => handlePlatformChange('Not Available')}
                                        />
                                        Not Available for Streaming
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="filter-dropdown-toggle" onClick={handleStreamingNowChange} style={{maxWidth: "200px"}}>
                    {isStreamingNow ? <RiCheckboxFill /> : <RiCheckboxBlankLine/>}
                    Streaming only
                </div>
            </div>
            
            {filteredUserList.length === 0 ? (
                <div className="user-list-empty">
                    <p>No movies match the selected filters.</p>
                </div>
            ) : (
                <div className={`user-list-items many-items`}>
                    {sortUserList(filteredUserList).map((item) => (
                        <div 
                        key={item.title_id} 
                        className="user-list-item"
                        onMouseEnter={() => handleMouseEnter(item.title_id)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(event) => handlePosterClick(item.title_id, event)}
                        >
                        <div className="user-list-item-poster">
                            {item.movieDetails && item.movieDetails.poster_path ? (
                            <img 
                                src={`https://image.tmdb.org/t/p/w300${item.movieDetails.poster_path}`} 
                                alt={item.movieDetails.title} 
                                className="user-list-item-poster-img"
                            />
                            ) : (
                            <div className="user-list-item-poster-placeholder">{item.movieDetails ? item.movieDetails.title : 'No Image'}</div>
                            )}
                        </div>
                        <p className="user-list-item-added">Added: {new Date(item.added_at).toLocaleDateString()}</p>
                        {activeHoverItem === item.title_id && (
                            <div className="user-list-item-hover-menu">
                            <div className="user-list-item-hover-title-container">
                                <h3 className="user-list-item-hover-title" onClick={(e) => { e.stopPropagation(); navigate(`/movie/${item.title_id}`); }}>{item.movieDetails.title}</h3>
                                <div className="user-list-item-hover-actions">
                                <div className="user-list-item-bookmark" onClick={(e) => { e.stopPropagation(); onToggleBookmark(item.title_id); }}>
                                    {isInWatchlist(item.title_id) ? <FaBookmark /> : <FaRegBookmark className="user-list-item-bookmark-add" />}
                                </div>
                                {new Date(item.movieDetails.release_date) <= new Date() && (
                                    <div className="user-list-item-watched" onClick={(e) => { e.stopPropagation(); onToggleWatched(item.title_id); }}>
                                    <IoTicket />
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className="user-list-item-hover-meta">
                                <p className="user-list-item-hover-release-date">
                                    {item.movieDetails.release_date && !isNaN(new Date(item.movieDetails.release_date).getTime())
                                        ? new Date(item.movieDetails.release_date).getFullYear()
                                        : 'Unreleased'}
                                </p>
                                <div className="user-list-item-hover-ratings">
                                {item.userRating != null && (
                                    <p className="user-list-item-hover-rating"><IoPerson /> {item.userRating.toFixed(1)}</p>
                                )}
                                {item.averageUserRating != null && (
                                    <p className="user-list-item-hover-rating"><IoPeople /> {item.averageUserRating.toFixed(1)}</p>
                                )}
                                </div>
                            </div>
                            <div className="user-list-item-hover-summary-container">
                                <p className="user-list-item-hover-summary">{item.movieDetails.overview}</p>
                            </div>
                            <div className="user-list-item-hover-buttons">
                                <button onClick={(e) => { e.stopPropagation(); navigate(`/movie/${item.title_id}`); }} className="user-list-item-see-more-btn">See more</button>
                            </div>
                            {item.movieDetails.watch_providers && item.movieDetails.watch_providers.stream && item.movieDetails.watch_providers.stream.length > 0 && (
                                <div className="user-list-item-streaming-platforms">
                                    <h4>Streaming on:</h4>
                                    <div className="user-list-item-streaming-platforms-list">
                                        {item.movieDetails.watch_providers.stream.map(provider => (
                                            <span key={provider.provider_id}>{provider.provider_name}</span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            </div>
                        )}
                        </div>
                        ))}
                </div>
            )}
        </>
        }

        {activeSection === 'watched' &&
            <div className="desktop-user-list-watched-container">
                {Object.entries(groupedWatchedMovies).map(([monthName, movies]) => (
                    monthName !== 'Others' && (
                        <WatchedMoviesSection
                            key={monthName}
                            monthName={monthName}
                            movies={movies}
                            handleMouseEnter={handleMouseEnter}
                            handleMouseLeave={handleMouseLeave}
                            handlePosterClick={handlePosterClick}
                            activeHoverItem={activeHoverItem}
                            isInWatchlist={isInWatchlist}
                            navigate={navigate}
                        />
                    )
                ))}
                {groupedWatchedMovies['Others'].length > 0 && (
                    <WatchedMoviesSection
                        key="Others"
                        monthName="Others"
                        movies={groupedWatchedMovies['Others']}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        handlePosterClick={handlePosterClick}
                        activeHoverItem={activeHoverItem}
                        isInWatchlist={isInWatchlist}
                        navigate={navigate}
                    />
                )}
            </div>
        }
        </div>
    );
};

function WatchedMoviesSection({ monthName, movies, handleMouseEnter, handleMouseLeave, handlePosterClick, activeHoverItem, isInWatchlist, navigate }) {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpanded = () => setIsExpanded(!isExpanded);

    return (
        <div className="desktop-user-list-watched-section">
            <div className="desktop-user-list-watched-section-header" onClick={toggleExpanded}>
                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                <h2>{monthName}</h2>
            </div>
            {isExpanded && (
                movies.length > 0 ? (
                    <div className="desktop-user-list-watched-grid">
                        {movies.map((movie) => (
                            <div 
                                key={movie.tmdbId} 
                                className="user-list-item"
                                onMouseEnter={() => handleMouseEnter(movie.tmdbId)}
                                onMouseLeave={handleMouseLeave}
                                onClick={(event) => handlePosterClick(movie.tmdbId, event)}
                            >
                                <div className="user-list-item-poster">
                                    {movie.data && movie.data.poster_path ? (
                                    <img 
                                        src={`https://image.tmdb.org/t/p/w300${movie.data.poster_path}`} 
                                        alt={movie.data.title} 
                                        className="user-list-item-poster-img"
                                    />
                                    ) : (
                                    <div className="user-list-item-poster-placeholder">{movie.data ? movie.data.title : 'No Image'}</div>
                                    )}
                                </div>
                                {activeHoverItem === movie.tmdbId && (
                                    <div className="user-list-item-hover-menu">
                                    <div className="user-list-item-hover-title-container">
                                        <h3 className="user-list-item-hover-title" onClick={(e) => { e.stopPropagation(); navigate(`/movie/${movie.tmdbId}`); }}>{movie.data.title}</h3>
                                        <div 
                                        className="user-list-item-bookmark">
                                        {isInWatchlist(movie.tmdbId) && <FaBookmark />}
                                        </div>
                                    </div>
                                    <div className="user-list-item-hover-meta">
                                        <p className="user-list-item-hover-release-date">{new Date(movie.data.release_date).getFullYear()}</p>
                                        <div className="user-list-item-hover-ratings">
                                        {movie.userRating != null && (
                                            <p className="user-list-item-hover-rating"><IoPerson /> {movie.userRating.toFixed(1)}</p>
                                        )}
                                        {movie.averageUserRating != null && (
                                            <p className="user-list-item-hover-rating"><IoPeople /> {movie.averageUserRating.toFixed(1)}</p>
                                        )}
                                        </div>
                                    </div>
                                    <div className="user-list-item-hover-summary-container">
                                        <p className="user-list-item-hover-summary">{movie.data.overview}</p>
                                    </div>
                                    <div className="user-list-item-hover-buttons">
                                        <button onClick={(e) => { e.stopPropagation(); navigate(`/movie/${movie.tmdbId}`); }} className="user-list-item-see-more-btn">See more</button>
                                    </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="desktop-user-list-no-movies">No movies watched in {monthName}</p>
                )
            )}
        </div>
    );
}