import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import moment from 'moment';
import { AuthProvider, useAuth } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import ReleaseCalendar from './components/ReleaseCalendar';
import ErrorBoundary from './components/ErrorBoundary';
import MovieDetails from './components/MovieDetails';
import MoviesByTag from './components/MoviesByTag';
import Registration from './components/Registration';
import Login from './components/Login';
import RatingPage from './components/RatingPage';
import Profile from './components/Profile';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import SearchResults from './components/SearchResults';
import MobileNavigation from './components/MobileNavigation';
import UserListManager from './components/UserListManager';
import GeneralMovieCollection from './components/GeneralMovieCollection';
import HomeCTA from './components/HomeCTA';
import FeaturedKeyword from './components/keywords/FeaturedKeyword'; // Import the new component
import './App.css';
import { WatchlistProvider } from './context/WatchlistContext';
import { getHorrorReleases, getTrendingHorrorMovies } from './api';
import ProfessionalIdentity from './components/personalities/ProfessionalIdentity';

export default function App() {
  const [calendarMovieCache, setCalendarMovieCache] = useState({});
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [trendingHorrorMovies, setTrendingHorrorMovies] = useState([]);
  const [loadingTrendingMovies, setLoadingTrendingMovies] = useState(true);

  const fetchMoviesForMonth = useCallback(async (year, month) => {
    const cacheKey = `${year}-${month}`;
    if (calendarMovieCache[cacheKey]) {
      return calendarMovieCache[cacheKey];
    }

    try {
      const response = await getHorrorReleases(year, month);
      const newMovies = response.data;
      setCalendarMovieCache(prevCache => ({
        ...prevCache,
        [cacheKey]: newMovies,
      }));
      return newMovies;
    } catch (error) {
      console.error('Error fetching horror movies:', error);
      return [];
    }
  }, [calendarMovieCache]);

  const handleMonthChange = useCallback((newMonth) => {
    setCurrentMonth(newMonth);
  }, []);

  useEffect(() => {
    const fetchTrendingHorrorMovies = async () => {
      try {
        const response = await getTrendingHorrorMovies();
        setTrendingHorrorMovies(response.data);
        setLoadingTrendingMovies(false);
      } catch (error) {
        console.error('Error fetching trending horror movies:', error);
        setLoadingTrendingMovies(false);
      }
    };

    fetchTrendingHorrorMovies();
  }, []);

  // Memoize the GeneralMovieCollection component
  const MemoizedGeneralMovieCollection = useMemo(() => {
    return (
      <GeneralMovieCollection
        movies={trendingHorrorMovies}
        loading={loadingTrendingMovies}
        title="Recently Rated"
      />
    );
  }, [trendingHorrorMovies, loadingTrendingMovies]);

  function Home() {
    const { isAuthenticated } = useAuth();
    return (
      <div className="home">
        <section className="home-intro">
          <h1>Welcome to the HorrrorVerse</h1>
          <p>HorrrorVerse is a horror movie discovery engine that connects survivors & scares, new and old.</p>
        </section>
        <section className="release-calendar-section">
          <h2 className="general-movie-collection-title">Release Calendar</h2>
          <ReleaseCalendar 
            movieCache={calendarMovieCache} 
            fetchMoviesForMonth={fetchMoviesForMonth} 
            currentMonth={currentMonth} 
            onMonthChange={handleMonthChange}
          />
        </section>
        <section className="trending-horror-section">
          {MemoizedGeneralMovieCollection}
        </section>
        {!isAuthenticated && <HomeCTA />}
        <div className="home-tmdb-attribution-mobile" style={{textAlign: 'center', marginTop: '20px', marginBottom: "20px", fontSize: "0.8rem", color: "grey"}}>The HorrrorVerse is 100% user supported.<br/><a href="https://buymeacoffee.com/horrrorverse" target="_blank" rel="noopener noreferrer">Buy us a coffee</a> if you want to help us keep the lights on.</div>
        <div className="home-tmdb-attribution home-tmdb-attribution-mobile" style={{textAlign: 'center', marginTop: '20px', marginBottom: "20px"}}>
          <a href="https://www.themoviedb.org" target="_blank" rel="noopener noreferrer">
            <img src="https://storage.googleapis.com/horrrorverse-public/tmdb-logo.svg" alt="TMDB Logo" style={{width: '40px', marginBottom: '10px'}} />
          </a>
          <div style={{fontSize: "0.8rem", color: "grey"}}>
            Movie data provided by <a href="https://www.themoviedb.org" target="_blank" rel="noopener noreferrer">The Movie Database (TMDB)</a>
          </div>
        </div>
      </div>
    );
  }

  function PrivateRoute({ children }) {
    const { isAuthenticated, loading } = useAuth();
    
    if (loading) {
      return <div>Loading...</div>;
    }
    
    return isAuthenticated ? children : <Navigate to="/login" />;
  }

  return (
    <AuthProvider>
      <WatchlistProvider>
        <Router>
          <div className="App">
            <Header />
            <main>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/movie/:id" element={<MovieDetails />} />
                  <Route path="/tag/:tag" element={<MoviesByTag />} />
                  <Route path="/create-account" element={<Registration />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/rate/:id" element={<RatingPage />} />
                  <Route 
                    path="/profile" 
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    } 
                  />
                  <Route 
                    path="/list" 
                    element={
                      <PrivateRoute>
                        <UserListManager />
                      </PrivateRoute>
                    } 
                  />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/search" element={<SearchResults />} />
                  {/* Add the new route for the keyword page */}
                  <Route path="/keyword/:keyword" element={<FeaturedKeyword />} />
                  {/* Add the catch-all route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path="/person/:id" element={<ProfessionalIdentity />} />
                </Routes>
              </ErrorBoundary>
            </main>
            <Footer />
            <MobileNavigation />
          </div>
        </Router>
      </WatchlistProvider>
    </AuthProvider>
  );
}